import marked from 'marked';
import he from 'he';

export function markdownToHtml(postContent) {
  const renderer = new marked.Renderer();

  renderer.link = (href, title, text) => {
    const t = title ? ` title="${title}"` : '';

    if (text.startsWith('<code>@#')) {
      return `${text.replace('<code>@#', '<code>@')} `;
    }

    return `
      <a target="_blank" href="${href}" rel="noopener noreferrer"${t}>
        ${text}
        <i class="material-icons" style="font-size: 13px; vertical-align: baseline">
          launch
        </i>
      </a>
    `;
  };

  marked.setOptions({
    renderer,
    breaks: true,
  });

  return marked(he.decode(postContent));
}
