import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import NProgress from 'nprogress';
import { CSSProperties, useEffect, useState } from 'react';
import { getZoomConnectURL, isZoomPro } from '../lib/api/video';
import notify from '../lib/notify';

type Props = {
  zoomConnected: boolean;
  suppressText?: boolean;
  fullWidth?: boolean;
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: '15px',
  },
  message: {
    marginRight: '25px',
  },
};

const ZoomConnected = (props: Props) => {
  const { zoomConnected, suppressText, fullWidth = true } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isPro, setIsZoomPro] = useState<boolean>(false);

  const zoomConnect = async () => {
    if (disabled) {
      return;
    }
    NProgress.start();
    setDisabled(true);

    try {
      const response = await getZoomConnectURL();
      window.location.href = response.url;
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  async function checkZoomPro() {
    const result = await isZoomPro();
    setIsZoomPro(result.isPro);
  }

  useEffect(() => {
    checkZoomPro();
  }, []);

  return (
    <div style={styles.container}>
      {zoomConnected ? (
        isPro ? (
          <Alert style={styles.message} severity={'success'}>
            Zoom connected.
          </Alert>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = 'https://zoom.us/pricing')}
              style={{ marginRight: '15px' }}
            >
              Upgrade Zoom
            </Button>

            <Alert style={styles.message} severity={'warning'}>
              Your zoom account is connected but it is not a pro account. A zoom pro account is required to enable all features.
            </Alert>
          </>
        )
      ) : (
        <Grid container>
          <Grid item xs={suppressText ? 12 : 4}>
            <Button
              variant="contained"
              color="primary"
              onClick={zoomConnect}
              disabled={disabled}
              fullWidth={fullWidth}
            >
              {zoomConnected ? 'Update Zoom Account' : 'Setup Zoom Account'}
            </Button>
          </Grid>
          {!suppressText && (
            <Grid item xs={7} style={{ margin: 'auto' }}>
              <div>Your Zoom account has not been connected.</div>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default ZoomConnected;
