import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ExplainerForm from './ExplainerForm';

const Explainer = ({ fullWidth }: { fullWidth: boolean }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <ExplainerForm modalOpen={modalOpen} handleClose={() => setModalOpen(false)} />
      <Button
        style={{ marginLeft: 'auto' }}
        variant="outlined"
        color="primary"
        onClick={() => setModalOpen(true)}
        fullWidth={fullWidth}
      >
        What can I do?
      </Button>
    </>
  );
};

export default Explainer;
