import React from 'react';
import { Button } from '@material-ui/core';

type Props = {
  onClick: () => void;
  fullWidth: boolean;
};

const ApplyForTeacherButton = (props: Props) => {
  return (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="outlined"
      color="primary"
      onClick={props.onClick}
      fullWidth={props.fullWidth}
    >
      Are you an educator?
    </Button>
  );
};

export default ApplyForTeacherButton;
