import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/application';

export const state = {
  NEW: 'new',
  APPROVED: 'approved',
  DENIED: 'denied',
};

export const createApplication = (forRole, data) =>
  sendRequestAndGetResponse(`${BASE_PATH}/`, {
    body: JSON.stringify({ for: forRole, data }),
    method: 'POST',
  });

export const getMyApplicationState = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/`, {
    method: 'GET',
  });

export const getApplications = () => sendRequestAndGetResponse(`${BASE_PATH}/`, { method: 'GET' });

export const getApplicationsByState = (state) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/state/${state}`, { method: 'GET' });
};

export const approveApplication = (appId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/approve`, {
    body: JSON.stringify({ appId: appId }),
    method: 'POST',
  });
