import { yupResolver } from '@hookform/resolvers/yup';
import { TextareaAutosize, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import NProgress from 'nprogress';
import { CSSProperties, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { createApplication } from '../../lib/api/application';
import notify from '../../lib/notify';

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
  titleMessage?: string;
  successMessage? : string;
};

type FormData = {
  firstName: string;
  lastName: string;
  degree: string;
  biography: string;
  award: string;
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  degree: yup.string().required(),
});

const styles: Record<string, CSSProperties> = {
  error: {
    color: ' #cb4335 ',
  },
};

const TeacherApplicationForm = (props: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { modalOpen, handleClose } = props;
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const applyForTeacher = handleSubmit(async (values) => {
    const { award, biography, degree, firstName, lastName } = values;
    if (disabled) {
      return;
    }
    NProgress.start();
    setDisabled(true);

    const data: Record<string, any> = {
      award,
      biography,
      degree,
      firstName,
      lastName,
    };

    try {
      await createApplication('creator', data);
      notify(props.successMessage?props.successMessage:'Applied Successfully');
      setDisabled(false);

      handleClose();
    } catch (error) {
      notify(error);
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  });

  return (
    <Dialog open={modalOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
      <DialogTitle id="form-dialog-title">Teacher Application {props.titleMessage}</DialogTitle>
      <DialogContent style={{ width:'1024px'}}>
        <form onSubmit={applyForTeacher}>
          <Typography variant={'subtitle2'}>Name</Typography>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              autoFocus
              label={'First Name'}
              type={'text'}
              name={'firstName'}
              fullWidth
              {...register('firstName')} 
            />
            {errors.firstName && <div style={styles.error}>{errors.firstName.message}</div>}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              label={'Last Name'}
              type={'text'}
              name={'lastName'}
              fullWidth
              {...register('lastName')}
            />
            {errors.lastName && <div style={styles.error}>{errors.lastName.message}</div>}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Typography variant={'subtitle2'}>Degree</Typography>
            <TextareaAutosize
              minRows={5}
              placeholder={'Please list your teaching credentials.  This is a way for students and schools to see how experienced you are.'}
              style={{ width: '100%' }}
              {...register('degree')} />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <Typography variant={'subtitle2'}>Biography</Typography>
            <TextareaAutosize
              minRows={5}
              placeholder={'Tell us a little bit about yourself (this will be visible to students on your profile and is editable)'}
              style={{ width: '100%' }}
              {...register('biography')} />
          </div>
          <div style={{ marginTop: '15px' }}>
            <Typography variant={'subtitle2'}>Awards</Typography>
            <TextareaAutosize
              minRows={5}
              placeholder={'Have you won any awards for education?  This is a great way to showcase how you\'ve gone above and beyond when helping educate'}
              {...register('award')}
              style={{ width: '100%' }} />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'primary'} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={applyForTeacher} color={'primary'} disabled={disabled} variant='contained'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherApplicationForm;
