import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

export interface ItemOptions {
  dataId: string;
  text: string;
  dataMoreId?: string;
  onClick: Function;
  separator?: boolean;
}

interface MenuOptions {
  id: string;
  dataId: string;
}

class MenuWithMenuItems extends React.PureComponent<{
  menuOptions: MenuOptions;
  itemOptions: ItemOptions[];
}> {
  public state = {
    menuElm: null,
  };

  public render() {
    const { menuOptions, itemOptions, children } = this.props;
    const { menuElm } = this.state;

    return (
      <div style={{ verticalAlign: 'middle' }}>
        {children ? (
          <div onClick={(e) => this.handleClick(e)}>{children} </div>
        ) : (
          <i
            aria-controls={menuElm ? menuOptions.id : null}
            data-id={menuOptions.dataId}
            aria-haspopup="true"
            style={{ fontSize: '14px', opacity: 0.7, cursor: 'pointer' }}
            className="material-icons"
            onClick={(e) => this.handleClick(e)}
          >
            more_vert
          </i>
        )}
        <Menu
          id={menuOptions.id}
          anchorEl={menuElm}
          open={Boolean(menuElm)}
          onClose={this.handleClose}
        >
          {itemOptions.map((option, i) =>
            option.separator ? (
              <hr style={{ width: '85%', margin: '10px auto' }} key={`separated-${i}`} />
            ) : (
              <MenuItem
                key={ `${option.dataId ? option.dataId : 'item'}-${i}` }
                data-id={option.dataId}
                data-more-id={option.dataMoreId}
                onClick={(e) => {
                  this.setState({ menuElm: null });
                  option.onClick(e);
                }}
              >
                {option.text}
              </MenuItem>
            ),
          )}
        </Menu>
      </div>
    );
  }

  public handleClick = (event) => {
    event.preventDefault();
    this.setState({ menuElm: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ menuElm: null });
  };
}

export default MenuWithMenuItems;
