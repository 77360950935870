import { User } from 'lib/store/user';

export const isCreator = (roles: string[]) => roles && roles.includes('creator');

export const isAdmin = (roles: string[]) => roles && roles.includes('admin');

export const isInRole = (roles: string[], requiredRole) =>
  !requiredRole || (roles && roles.includes(requiredRole));

export const canCharge = (user: User) => user && user.hasStripeConnected && user.hasZoomConnected;
