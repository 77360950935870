import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Link from 'next/link';
import React from 'react';

import Confirmer from '../common/Confirmer';
import MenuWithLinks from '../common/MenuWithLinks';
import Notifier from '../common/Notifier';

import { SvgIcon, SvgIconProps, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AndroidIcon from '@material-ui/icons/Android';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PlusOne from '@material-ui/icons/PlusOne';
import SettingsIcon from '@material-ui/icons/Settings';
import withStyles from '@material-ui/styles/withStyles';
import Explainer from 'components/dashboard/Explainer';
import TeacherApplication from 'components/teacher-application/TeacherApplication';
import ZoomConnected from 'components/ZoomConnected';
import { isAdmin, isCreator } from 'helpers/authHelpers';
import Router from 'next/router';
import { Store } from '../../lib/store';
import DiscussionList from '../discussions/DiscussionList';

import PeopleIcon from '@material-ui/icons/People';
import { Alert } from '@material-ui/lab';

function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const styleGrid = {
  width: '100vw',
  minHeight: '100vh',
  maxWidth: '100%',
  backgroundColor: grey[100],
};

const styleChild = { 
  padding: '0px 10px',
};

const styleGridIsMobile = {
  width: '100vw',
  minHeight: '100vh',
  maxWidth: '100%',
  padding: '0px 3px 0px 3px',
  backgroundColor: grey[100],
};

const styleSideBarDiv = {
  paddingLeft: '5px',
  paddingBottom: '2px',
};

function LayoutWrapper({
  children,
  isMobile,
  firstGridItem,
  store,
  theme,
}: {
  children: React.ReactNode;
  isMobile: boolean;
  firstGridItem: boolean;
  store: Store;
  theme: Theme;
}) {
  const options = [
    {
      text: 'Dashboard',
      href: `/your-dashboard`,
      as: `/dashboard`,
      simple: true,
    },
    {
      text: 'Settings',
      href: '/your-settings',
      highlighterSlug: '/your-settings',
    },
    {
      separator: true,
    },
    {
      text: 'Log out',
      href: `/logout`,
      as: `/logout`,
    },
  ];
  const currentUser = store.currentUser;

  const suggestions = [];
  const hasSuggestions = suggestions && suggestions.length > 0;
  /*
  console.log(theme.palette.background);
  console.log(
    `main: ${isMobile ? styleGridIsMobile.backgroundColor : styleGrid.backgroundColor} sidebar:${
      theme.palette.background.paper
    }`,
  );
  */
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        style={isMobile ? styleGridIsMobile : styleGrid}
      >
        {children}

        {firstGridItem && currentUser ? (
          <Grid
            item
            sm={2}
            xs={12}
            style={{
              borderLeft: store.currentTeam ? '1px #707070 solid' : '',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <div style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '5px' }}>
                {store.currentUser.email}
              </div>
              <MenuWithLinks options={options}>
                <Avatar
                  src={store.currentUser.avatarUrl}
                  alt={store.currentUser.firstName}
                  style={{
                    margin: '20px auto',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    width: '40px',
                    height: '40px',
                  }}
                />
                <i className="material-icons" color="action" style={{ verticalAlign: 'super' }}>
                  arrow_drop_down
                </i>
              </MenuWithLinks>
            </div>
            <p />
            <p />
            <div style={styleSideBarDiv}>
              <Button
                variant="outlined"
                onClick={() => Router.push('/dashboard', '/dashboard')}
                fullWidth={true}
              >
                <HomeIcon color="primary" />
                <span style={{ marginRight: 'auto' }}>Home</span>
              </Button>
            </div>
            {isCreator(store.currentUser.roles) && (<>
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() => Router.push('/invoices', '/invoices')}
                  fullWidth={true}
                >
                  <CreditCardIcon color="primary" />
                  <span style={{ marginRight: 'auto' }}>Invoices</span>
                </Button>
              </div>
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    Router.push(
                      '/profile/' + store.currentUser._id,
                      '/profile/' + store.currentUser._id,
                    )
                  }
                  fullWidth={true}
                >
                  <AccountCircleIcon />
                  <span style={{ marginRight: 'auto' }}>Profile</span>
                </Button>
              </div>
              
              </>)}
            {isCreator(store.currentUser.roles) && false && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() => Router.push('/suggestions', '/suggestions')}
                  fullWidth={true}
                >
                  <EmojiObjectsIcon />
                  <span style={{ marginRight: 'auto' }}>Suggested Classes</span>
                </Button>
              </div>
            )}
            {isCreator(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    Router.push(
                      `/profile/${store.currentUser._id}/templates`,
                      `/profile/${store.currentUser._id}/templates`,
                    )
                  }
                  fullWidth={true}
                >
                  <LibraryBooksIcon />
                  <span style={{ marginRight: 'auto' }}>Class templates</span>
                </Button>
              </div>
            )}
            {hasSuggestions && isCreator(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    Router.push(
                      `/suggestions`,
                      `/suggestions`,
                    )
                  }
                  fullWidth={true}
                >
                  <EmojiObjectsIcon />
                  <span style={{ marginRight: 'auto' }}>Suggested Classes</span>
                </Button>
              </div>
            )}

            {isCreator(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    Router.push(
                      process.env.DISCORD_INVITE_URL,
                      process.env.DISCORD_INVITE_URL,
                    )
                  }
                  fullWidth={true}
                >
                  <PeopleIcon />
                  <span style={{ marginRight: 'auto' }}>Join the community</span>
                </Button>
              </div>
            )}
            {isAdmin(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() => Router.push('/admin', '/admin')}
                  fullWidth={true}
                >
                  <AndroidIcon />
                  <span style={{ marginRight: 'auto' }}>Admin</span>
                </Button>
              </div>
            )}
            {isAdmin(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() => Router.push('/dev', '/dev')}
                  fullWidth={true}
                >
                  <SettingsIcon />
                  <span style={{ marginRight: 'auto' }}>Dev</span>
                </Button>
              </div>
            )}
            {isAdmin(store.currentUser.roles) && (
              <div style={styleSideBarDiv}>
                <Button
                  variant="outlined"
                  onClick={() => alert('not yet built')}
                  fullWidth={true}
                  disabled={true}
                >
                  <PlusOne />
                  <span style={{ marginRight: 'auto' }}>Manual Add Teacher</span>
                </Button>
              </div>
            )}
            <p />
            <hr />
            <p />
            {currentUser.quests.hasApplication && !isCreator(currentUser.roles) && (<Alert severity='info'>Your application has been submitted</Alert>)}
            {currentUser.quests.hasApplication || isCreator(currentUser.roles) ? (
              !store.isMobile &&
              !isCreator(currentUser.roles) &&
              !currentUser.hasZoomConnected && (
                <div style={styleSideBarDiv}>
                  <ZoomConnected zoomConnected={currentUser.hasZoomConnected} suppressText={true} />
                </div>
              )
            ) : (
              <div style={styleSideBarDiv}>
                <TeacherApplication fullWidth={true} />
              </div>
            )}
            <div style={styleSideBarDiv}>
              <Explainer fullWidth={true} />
            </div>


            <DiscussionList store={store} team={store.currentTeam} isMobile={isMobile} />
          </Grid>
        ) : null}
      </Grid>
      <Notifier />
      <Confirmer />
    </React.Fragment>
  );
}

type Props = {
  children: React.ReactNode;
  isMobile?: boolean;
  firstGridItem?: boolean;
  store?: Store;
  teamRequired?: boolean;
  theme: Theme;
};

class Layout extends React.Component<Props> {
  public render() {
    const { children, isMobile, firstGridItem, store, teamRequired, theme } = this.props;
    const { currentUser, currentTeam } = store;

    //const isThemeDark = currentUser && currentUser.darkTheme === true;

    // console.log(this.props.store.currentUser.darkTheme);

    // const isThemeDark = false;

    // console.log(isMobile);

    // console.log(currentTeam);

    if (!currentUser) {
      //console.log('no user layout');
      return (
        <LayoutWrapper
          firstGridItem={firstGridItem}
          isMobile={isMobile}
          store={store}
          theme={theme}
        >
          <Grid item sm={12} xs={12}>
            {children}
          </Grid>
        </LayoutWrapper>
      );
    }

    if (!currentTeam) {
      //console.log('no team layout');
      if (teamRequired) {
        //console.log('team required layout');
        return (
          <LayoutWrapper
            firstGridItem={firstGridItem}
            isMobile={isMobile}
            store={store}
            theme={theme}
          >
            <Grid item sm={10} xs={12}>
              <div style={{ padding: '20px' }}>
                Select existing team or create a new team.
                <p />
                <Link href="/create-team" as="/create-team">
                  <Button variant="outlined" color="primary">
                    Create new team
                  </Button>
                </Link>
              </div>
            </Grid>
          </LayoutWrapper>
        );
      } else {
        //console.log('team NOT required layout');
        return (
          <LayoutWrapper
            firstGridItem={firstGridItem}
            isMobile={isMobile}
            store={store}
            theme={theme}
          >
            <Grid item sm={10} xs={12} style={styleChild}>
              {children}
            </Grid>
          </LayoutWrapper>
        );
      }
    }

    //console.log('has team layout');
    return (
      <LayoutWrapper firstGridItem={firstGridItem} isMobile={isMobile} store={store} theme={theme}>
        <Grid item sm={firstGridItem ? 10 : 12} xs={12}>
          <div>
            {!isMobile && store.currentUrl.includes('settings') && (
              <React.Fragment>
                <i
                  style={{
                    float: 'left',
                    margin: '15px 0px 10px 25px',
                    opacity: 0.8,
                    fontSize: '18px',
                    cursor: 'pointer',
                    verticalAlign: 'top',
                  }}
                  className="material-icons"
                  onClick={async () => {
                    await store.currentUser.toggleTheme(!store.currentUser.darkTheme);
                  }}
                >
                  lens
                </i>
              </React.Fragment>
            )}
            <div style={{ clear: 'both' }} />
          </div>
          {children}
        </Grid>
      </LayoutWrapper>
    );
  }
}

export default withStyles({}, { withTheme: true })(Layout);
