import { Grid } from '@material-ui/core';
import React from 'react';

const styles = {
  header: {
    backgroundColor: '#ededed',
  },
  p: { margin: '10px' },
};

const ExplainerBlock = () => {
  return (
    <>
      <Grid container>
        <Grid item sm={6}>
          <h2 style={styles.header}>Student / Parent Dashboard</h2>
          <p style={styles.p}>
            On their dashboard they can see a list of their classes. from there they can authorize
            payment or confirm their spot in the class. Future improvements include only showing
            classes from today with a toggle for past and future. Clicking the '...' will take you
            to the class details screen
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Teacher Dashboard</h2>
          <p style={styles.p}>
            Prompts and information to assist teachers is across the top.
            <br />
            Teachers are also put into a group with other teachers so they can post messages to each
            other.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Following</h2>
          <p style={styles.p}>
            Active but not useful, anyone can follow a teacher by clicking the follow button on
            their profile page. In the future we'll update followers when they create a public class
            or post new content.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Recommendations</h2>
          <p style={styles.p}>
            very simple currently, we pick 3 teachers who are signed up and have all their info
            filled out. this should drive people to the profile page for that teacher
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Browsing Classes</h2>
          <p style={styles.p}>
            not yet active but classes that are marked as public would allow a user to look at
            public classes (without seeing the students) and join a class. If a class is full they
            could express interest in it which could prompt a teacher to set up another class.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Creating Classes</h2>
          <p style={styles.p}>
            teachers have the ability to create new classes that are free or paid (paid requires a
            stripe account). We are adding a toggle for recording the meeting which would require a
            zoom pro account.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Class details</h2>
          <p style={styles.p}>
            <h3>For attendees</h3>
            They can make sure they're registered properly for the class. Later they will be able to
            give feedback on the class and rate the class and teacher
            <h3>For teachers</h3>
            They can see the registration status of all the attendees. During the class they can
            give feedback to the students that will be saved in the class timeline. When the teacher
            ends the class they will be sent an attendance report. The students will be sent their
            meeting timeline (when the class started, when they joined, if they got feedback, when
            they left)
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Suggesting a class</h2>
          <p style={styles.p}>
            Students and teachers can suggest a class. currently this is stored in the database and
            thats it. However, in the future we can allow students to view suggestions and follow
            it. This will increase the number of students 'interested' in a class. Teachers could
            then review suggestions and interest and create a class for that suggestion. Students
            who are interested would be emailed and prompted to sign up for that class.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Tipjar</h2>
          <p style={styles.p}>
            Students are able to give Teachers a tip after a class completes. A tip can also be
            given to a teacher by visiting their profile page. When giving a tip you can send a
            personalized note to say thank you to the teacher
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Inviting a teacher</h2>
          <p style={styles.p}>
            approved teachers can issue an invite to someone by email asking them to sign up. those
            users are treated as regular until they fill out an application and are approved
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Badges</h2>
          <p style={styles.p}>
            not active yet but badges can be granted to teachers and those badges can be seen on
            their profile screen
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Applying to teach</h2>
          <p style={styles.p}>
            Regular users can apply to be a teacher on the dashboard. This asks them to fill out a
            form including their credentials and a bio on them.
          </p>
        </Grid>
        <Grid item sm={6}>
          <h2 style={styles.header}>Teacher Profiles</h2>
          <p style={styles.p}>
            Shows basic info about teachers. Has a stub for their video intro (need a way to upload
            & serve that). Has a follow button for other users. Will show a list of their public
            classes eventually. Will give them the ability to post different forms of content later
            on as well as have subscriptions
          </p>
        </Grid>
        <Grid item sm={12}>
          <h1 style={styles.header}>DeAuthorizing Zoom</h1>
          <p style={styles.p}>
            <ol>
              <li>
                To uninstall your Zoom integration. Simply navigate to the 'your-settings' page.{' '}
              </li>
              <li>You will see a section labeled your Zoom account</li>
              <li>click the disconnect button to remove your Zoom integration</li>
            </ol>
          </p>
        </Grid>
        <Grid item sm={12}>
          <h1 style={styles.header}>Gaining access to stored recordings</h1>
          <p style={styles.p}>
            If you attended a class that had a recording and you'd like to get a copy of it. Please
            email support@drivenslns.com and we will accommodate your request.
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default ExplainerBlock;
