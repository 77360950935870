import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExplainerBlock from './ExplainerBlock';

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
};

const ExplainerForm = (props: Props) => {
  const { modalOpen, handleClose } = props;

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">What can I currently do with Doceo</DialogTitle>
        <DialogContent>
          <ExplainerBlock />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={'primary'}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExplainerForm;
