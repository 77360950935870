import React, { useState } from 'react';
import TeacherApplicationForm from './TeacherApplicationForm';
import ApplyForTeacherButton from './ApplyForTeacherButton';

const TeacherApplication = ({ fullWidth }: { fullWidth: boolean }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <TeacherApplicationForm modalOpen={modalOpen} handleClose={() => setModalOpen(false)} />
      <ApplyForTeacherButton onClick={() => setModalOpen(true)} fullWidth={fullWidth} />
    </>
  );
};

export default TeacherApplication;
